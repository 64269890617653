.si-float-wrapper {
  position: absolute;
  width: 100%; }
  .si-float-wrapper,
  .si-float-wrapper * {
    box-sizing: border-box; }

[class*='si-wrapper'] {
  display: flex;
  position: absolute;
  align-items: center;
  font-size: 14px;
  cursor: default; }

.si-wrapper-top {
  flex-direction: column;
  margin-top: -40px;
  margin-left: 0px;
  transform: translate(-50%, -100%); }

.si-wrapper-bottom {
  flex-direction: column-reverse;
  margin-top: 0px;
  margin-left: 0px;
  transform: translate(-50%, 0); }

.si-wrapper-left {
  margin-top: -20px;
  margin-left: -11px;
  transform: translate(-100%, -50%); }

.si-wrapper-right {
  flex-direction: row-reverse;
  margin-top: -20px;
  margin-left: 11px;
  transform: translate(0, -50%); }

[class*='si-shadow-wrapper'] {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.29804;
  z-index: 1; }

.si-shadow-wrapper-top,
.si-shadow-wrapper-bottom {
  flex-direction: column; }

.si-shadow-pointer-bottom,
.si-shadow-pointer-right {
  order: -1; }

.si-shadow-frame {
  box-shadow: 0 1px 3px 0 #000; }

[class*='si-shadow-pointer'] {
  position: relative;
  width: 15px;
  height: 15px;
  margin: auto; }

[class*='si-shadow-inner-pointer'] {
  position: absolute;
  width: 141%;
  height: 141%;
  box-shadow: -0.70711px 0.70711px 3px 0 #000; }

.si-shadow-inner-pointer-top {
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg); }

.si-shadow-inner-pointer-bottom {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) rotate(-45deg); }

.si-shadow-inner-pointer-left {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg); }

.si-shadow-inner-pointer-right {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%) rotate(-45deg); }

.si-frame {
  position: relative;
  flex: 1 1 auto;
  border-radius: 3px;
  overflow: hidden;
  z-index: 2; }

.si-content-wrapper {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 30px;
  background-color: #fff; }
  .si-has-border .si-content-wrapper {
    border: 1px solid #bbb; }

.si-content {
  overflow: auto; }

.si-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  color: inherit;
  font-family: Arial, Baskerville, monospace;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.5;
  appearance: none; }
  .si-close-button:hover, .si-close-button:focus {
    opacity: 0.7; }

[class*='si-pointer-border'] {
  position: absolute;
  border: 15px solid transparent;
  z-index: 3; }

[class*='si-pointer-bg'] {
  position: relative;
  border: 15px solid transparent;
  z-index: 4; }
  .si-has-border [class*='si-pointer-bg'] {
    border-width: 15px; }

.si-pointer-border-top,
.si-pointer-border-bottom {
  left: 50%;
  transform: translate(-50%, 0); }

.si-pointer-border-left,
.si-pointer-border-right {
  top: 50%;
  transform: translate(0, -50%); }

.si-pointer-top {
  border-bottom: 0; }

.si-pointer-border-top {
  bottom: 0;
  border-top-color: #bbb; }

.si-pointer-bg-top {
  border-top-color: #fff; }
  .si-has-border .si-pointer-bg-top {
    top: -1px;
    margin-bottom: 0px; }

.si-pointer-bottom {
  border-top: 0; }

.si-pointer-border-bottom {
  top: 0;
  border-bottom-color: #bbb; }

.si-pointer-bg-bottom {
  border-bottom-color: #fff; }
  .si-has-border .si-pointer-bg-bottom {
    bottom: -1px;
    margin-top: 0px; }

.si-pointer-left {
  border-right: 0; }

.si-pointer-border-left {
  right: 0;
  border-left-color: #bbb; }

.si-pointer-bg-left {
  border-left-color: #fff; }
  .si-has-border .si-pointer-bg-left {
    left: -1px;
    margin-right: 0px; }

.si-pointer-right {
  border-left: 0; }

.si-pointer-border-right {
  left: 0;
  border-right-color: #bbb; }

.si-pointer-bg-right {
  border-right-color: #fff; }
  .si-has-border .si-pointer-bg-right {
    right: -1px;
    margin-left: 0px; }
